<template>
<div id="app">
  
  <router-view/>
  </div>
</template>

<script>
export default {
  watch: {
    '$route'(to) {
      document.title = to.meta.title || 'Your Website'
    }
  },
}
</script>

<style>
#app {
  font-family: "apercu", "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:white;

}

</style>
