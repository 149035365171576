export default [
  {
    value: "galerie-art-matt",
    weights:
      [
          { value: 0.09, text: "90"},
        { value: 0.10, text: "100"},
        { value: 0.115, text: "115"},
        { value: 0.13, text: "130"},
        { value: 0.152, text: "150" },
        { value: 0.173, text: "170" }
      ],
    name: "Galerie Art Matt"
  },

  {
    value: "galerie-art-silk",
    weights:
      [
          { value: 0.078, text: "90"},
          { value: 0.087, text: "110"},
        { value: 0.097, text: "115"},
        { value: 0.109, text: "130"},
        { value: 0.126, text: "150" },
        { value: 0.145, text: "170" }
      ],
    name: "Galerie Art Silk"
  },
    {
    value: "galerie-art-gloss",
    weights:
      [ 
        { value: 0.085, text: "115"},
        { value: 0.094, text: "130" },
        { value: 0.11, text: "150" },
        { value: 0.128, text: "170" },       
      ],
    name: "Galerie Art Gloss"
  }, 
  {
    value: "g_print",
    weights:
      [
        { value: 0.087, text: "90" },
        { value: 0.098, text: "100" },
        { value: 0.113, text: "115" },
        { value: 0.129, text: "130" },
        { value: 0.150, text: "150" },
        { value: 0.172, text: "170" }
      ],
    name: "G-print"
  },
  {
    value: "arctic_volume_white",
    weights:
      [
        { value: 0.099, text: "90" },
        { value: 0.110, text: "110" },
        { value: 0.126, text: "115" },
        { value: 0.143, text: "130" },
        { value: 0.165, text: "150" },
        { value: 0.187, text: "170" }
      ],
    name: "Arctic Volume White"
  },
  
  {
    value: "edixion",
    weights:
      [
        { value: 0.114, text: "90" },
        { value: 0.123, text: "100" },
        { value: 0.14, text: "120" },
        { value: 0.172, text: "150" },
        { value: 0.185, text: "170" }
      ],
    name: "Edixion"
  },
  {
    value: "munken",
    weights:
      [
        { value: 0.118, text: "90" },
        { value: 0.113, text: "100" },
        { value: 0.136, text: "120" },
        { value: 0.147, text: "130" },
        { value: 0.17, text: "150" },
        { value: 0.192, text: "170" }
      ],
    name: "Munken"
  },
  {
    value: "amber_graphic",
    weights:
      [
        { value: 0.099, text: "80" },
        { value: 0.110, text: "90" },
        { value: 0.122, text: "100" },
        { value: 0.145, text: "120" },
        { value: 0.180, text: "150" },
        { value: 0.204, text: "170" }
      ],
    name: "Amber Graphic"
  }
  
]