    import Vue from 'vue' // Include Vue
    import Router from 'vue-router' // Include Vue Router libary
    import Home from './components/Home.vue' 
    import Vektkalkulator from './components/Vektkalkulator.vue'
    import Ryggkalkulator from './components/Ryggkalkulator.vue'

    Vue.use(Router)
    
    export default new Router({
      routes: [
        {
          path: '/',
          name: 'home',
          component: Home,
            meta: {
                title: 'Kalkulator'
            }
        },
        {
          path: '/vektkalkulator',
          name: 'vektkalkulator',
          component: Vektkalkulator,
            meta: {
                title: 'Vektkalkulator'
            }
        },
        {
          path: '/ryggkalkulator',
          name: 'ryggkalkulator',
          component: Ryggkalkulator,
          meta: {
             title: 'Ryggkalkulator'
          }
        }
      ]
    })