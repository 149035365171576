<template>
  <div>

    <div class="web fluid-container font-light md:py-40 px-5 lg:px-24 md:px-44">
      <button
        v-if="isOpen"
        @click="isOpen = false"
        tabindex="-1"
        class="fixed z-10 inset-0 h-full w-full bg-black-200 opacity-50 cursor-default"
      ></button>
      <h1 class=" text-3xl pb-5">Ryggkalkulator</h1>

      <form onsubmit="return false">
        <div class="flex flex-wrap justify-between">
          <div class="flex-col w-full lg:w-1/3">
            <p class=" text-xl pb-6 font-normal  ">
              Skal du lage omslag til trykksak? Denne kalkulatoren regner ut
              tykkelsen på ryggen.
            </p>

            <h2 class=" leading-10 pb-6">VELG COVER</h2>

            <div class="flex w-2/3 justify-between pb-16">
              <div class="flex">
                <label class="radio-input inline-flex">
                  <input
                    type="radio"
                    checked="checked"
                    name="radio-colors"
                    id="softcover"
                    value="Softcover"
                    v-model="coverType"
                  >
                  <span class="checkmark"></span>
                </label>
                <h2 class="inline-block text-lg">Softcover</h2>
              </div>
              <div class="flex">
                <label class="radio-input inline-flex">
                  <input
                    type="radio"
                    name="radio-colors"
                    id="hardcover"
                    value="Hardcover"
                    v-model="coverType"
                  >
                  <span class="checkmark"></span>
                </label>
                <h2 class="inline-block  text-lg">Hardcover</h2>
              </div>
            </div>


            <div class="flex justify-between">
              <button tabindex="-3" class="focus:outline-none pr-40" v-once @click="showPages = !showPages">
                <h2 class="w-full  leading-10">ANTALL SIDER</h2>
              </button>

            </div>
            <!--<div v-if="!showPages"  class="w-full border border-green-500 "></div> -->

            <transition name="fade">
              <input
                min="0"
                maxlength="4"
                oninput="this.value=this.value.slice(0,this.maxLength||0/1); this.value=(this.value  < 0) ? (0) : this.value;"
                v-model.number="numberOfPages"
                type="number"
                id="pages"
                name="pages"
                class="bg-white text-black-200 border-3 border-black-100 rounded-none w-full py-4 px-4 block leading-normal outline-none focus:border-green-500"
              >
            </transition>
            <div
              class="mt-4 yellow-box p-5 z-50 text-black-100"
              v-show="numberOfPages < 48"
            >Anbefalt minimum sidetall: 48
            </div>
            <div v-if="coverType === 'Softcover'">
              <div
                  v-if="isOpen || numberOfPages > 650"
                  class="mt-4 yellow-box h-20 p-5 z-50 text-black-100"
              >Vi anbefaler ikke bøker over 650 sider for soft cover
              </div>
            </div>

            <div class="flex justify-between py-16">
              <div class="w-2/5 flex-col">
                <h2 class="leading-10 overflow-x-hidden font-normal">BREDDE I MM</h2>
                <input
                  min="0"
                  maxlength="4"
                  max="1000"
                  oninput="this.value=(this.value  < 0) ? (0) : this.value; this.value=this.value> 1000? (1000): this.value"
                  v-model.number="bredde"
                  type="number"
                  id="width"
                  name="width"
                  class="bg-white text-black-200 border-3 border-black-100 rounded-none w-full py-4 px-4 block leading-normal outline-none focus:border-green-500"
                >
              </div>
              <div class="w-2/5 flex-col">
                <h2 class="leading-10">HØYDE I MM</h2>
                <input
                  min="0"
                  max="1000"
                  maxlength="4"
                  oninput="this.value=(this.value  < 0) ? (0) : this.value; this.value=this.value > 1000? (1000): this.value"
                  v-model.number="hoyde"
                  type="number"
                  id="height"
                  name="height"
                  class="bg-white text-black-200 border-3 border-black-100 rounded-none w-full py-4 px-4 block leading-normal outline-none focus:border-green-500"
                >
              </div>
            </div>

           <div class="pb-4">
            <label class="leading-10 ">PAPIRTYPE </label>
            <div class="relative ">
              <select
                v-model="paperType"
                v-bind:value="paperTypes[0].value"
                class=" block text-xl text-black-200 appearance-none rounded-none w-full bg-white py-4 mb-4 px-4 pr-8 leading-normal border-3 border-black-100 outline-none focus:border-green-500"
              >
                <option
                  id="ptype"
                  v-for="ptype in paperTypes"
                  v-bind:key="ptype.value"
                  v-bind:value="ptype.value"
                  class="bg-white text-xl text-black-200"
                >{{ ptype.name }}
                </option>
              </select>

              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-black-200"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  ></path>
                </svg>
              </div>
            </div>
            </div>

           <div class="pb-8">
            <label class="leading-10">GRAMVEKT PÅ PAPIR</label>
            <div class="relative ">
              <select
                v-model="weight"
                class="block text-black-200 appearance-none rounded-none w-full bg-white py-4 px-4 pr-8 leading-normal border-3 border-black-100 outline-none focus:border-green-500"
                id="paperWeight"
              >
                <option
                  id="weight"
                  v-for="paper in papersList"
                  v-bind:key="paper.value"
                  v-bind:value="paper.value"
                >{{ paper.text }}
                </option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0  text-5xl right-0 flex items-center px-2 text-black-200 z-10"
              >
                <svg
                  class="fill-current  h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  ></path>
                </svg>
              </div>
            </div>
            <div v-if="coverType === 'Softcover'" class="pt-8">
              <label class="text-grey-500 leading-10 uppercase ">omslag</label>
              <div class="relative ">
                <select
                  v-model="selectedOvertrekkTykkelse"
                  class="block text-black-200 appearance-none rounded-none w-full bg-white py-4 px-4 pr-8 leading-normal border-3 border-black-100 outline-none focus:border-green-500"
                  id="grid-state"
                >
                  <option
                    id="covers"
                    v-for="cover in covers"
                    v-bind:key="cover.value"
                    v-bind:value="cover.value"
                  >{{ cover.text }}
                  </option>
                </select>
                <div
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-black-200"
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
           </div>


            <div v-if="coverType === 'Hardcover'" class="">
              <label class="leading-10 pt-4">PAPIRTYPE PÅ FORSATS/BAKSATS</label>
              <div class="relative">
                <select
                  v-model="forsatsPaperType"
                  v-bind:value="forsatsPaperTypes[0].value"
                  class="block text-xl  text-black-200 appearance-none rounded-none w-full bg-white py-4 px-4 pr-8 leading-normal border-3 border-black-100 outline-none focus:border-green-500"
                >
                  <option
                    id="forsatsptype"
                    v-for="forsatsptype in forsatsPaperTypes"
                    v-bind:key="forsatsptype.value"
                    v-bind:value="forsatsptype.value"
                    class="bg-white text-xl text-black-200"
                  >{{ forsatsptype.name }}
                  </option>
                </select>

                <div
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-black-200"
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    ></path>
                  </svg>
                </div>
              </div>

              <h2 class="leading-10 pt-8">GRAMVEKT PÅ PAPIR PÅ FORSATS/BAKSATS</h2>
              <div class="relative mb-8">
                <select
                  v-model="forsatsweight"
                  class="block text-black-200 appearance-none rounded-none w-full bg-white py-4 px-4 pr-8 leading-normal border-3 border-black-100 outline-none focus:border-green-500"
                  id="grid-state"
                >
                  <option
                    id="forsatsweight"
                    v-for="forsatspaper in forsatsPapersList"
                    v-bind:key="forsatspaper.value"
                    v-bind:value="forsatspaper.value"
                  >{{ forsatspaper.text }}
                  </option>
                </select>
                <div
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-black-200"
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    ></path>
                  </svg>
                </div>
              </div>

              <h2 class=" leading-10 ">PAPP OG OVERTREKK - TYKKELSE I MM</h2>
              <div class="relative mb-10">
                <select
                  v-model="selectedOverdraft"
                  class="block text-black-200 appearance-none rounded-none w-full bg-white py-4 px-4 pr-8 leading-normal border-3 border-black-100 outline-none focus:border-green-500"
                  id="overdraft"
                >
                  <option
                    v-for="overdraft in overdrafts"
                    v-bind:key="overdraft.value"
                    v-bind:value="overdraft.value"
                  >{{ overdraft.value }}
                  </option>
                </select>

                <div
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-black-200"
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    ></path>
                  </svg>
                </div>
              </div>

              <div class="flex mb-8">
                <label class="radio-square inline-flex">
                  <input
                    type="checkbox"
                    name="radio-colors"
                    id="coveroption"
                    value="coveroption"
                    v-model="coverOption"
                  >
                  <span class="checkmark-square"></span>
                </label>
                <h2 class="inline-block  text-lg">Jeg ønsker vareomslag </h2>
              </div>
              <transition name="fade">
                <div v-show="coverOption" class="flex-col ">
                  <h2 class="leading-10 overflow-x-hidden">Klaffer lengde</h2>
                  <input
                    min="60"
                    maxlength="4"
                    max="120"
                    oninput="this.value=this.value> 120? (120): this.value"
                    v-model.number="klafferlength"
                    type="number"
                    id="klafferlength"
                    name="klafferlength"
                    class="bg-white text-black-200 border-3 border-black-100 rounded-none w-full py-4 px-4 block leading-normal outline-none focus:border-green-500"
                  >
                </div>
              </transition>
            </div>
          </div>

          <div class="w-full lg:w-3/5  text-grey-500">
            <div class="sticky top-0">
              <div class="flex-col lg:pl-16 justify-center">
                <div class="flex lg:w-3/4  text-grey-500 bg-grey-800 px-6 pt-5">
                  <div
                    id="clipout"
                    class="flex w-full justify-between rounded border border-dashed border-grey-800 pl-2 py-1 md:pr-10"
                  >
                    <div class="flex-col w-2/5  pb-5">
                      <h3 class="text-xl leading-loose ">Ryggbredde</h3>

                      <h2
                        v-if="coverType === 'Softcover'"
                        id="result"
                        class="text-3xl font-bold text-green-500 leading-normal"
                      >{{ result }} mm</h2>
                      <h2
                        v-if="coverType === 'Hardcover'"
                        id="hard-cover-result"
                        class="text-3xl font-bold text-green-500 leading-normal"
                      >{{ hardCoverResult}} mm</h2>
                      <div class="flex flex-wrap text-xl">
                        <h3 id="presult" class="md:pr-4 ">{{ displayPages }}</h3>
                        <h3 id="numsheets">{{ displaySheets }}</h3>
                      </div>
                      <h4
                        v-show="result < 3 && result > 0"
                        class="pt-2 text-yellow-400"
                      >Det er ikke mulig å produsere bøker med rygg mindre enn 3 mm</h4>
                    </div>

                    <div v-if="coverType === 'Hardcover' && coverOption" class="w-2/5 flex-col pb-5">
                      <h3 class="text-xl leading-loose  pl-2">Løsrygg</h3>
                      <h2
                        class="text-3xl font-bold text-green-500 leading-normal"
                      >{{ lossRygg }} mm</h2>
                    </div>
                  </div>
                  <!-- <button
                    v-show="coverType === 'Softcover'"
                    tabindex="-2"
                    @mouseenter="clipOut"
                    @mouseleave="leave"
                    type="button"
                    class="w-1/12 copy-button flex self-start justify-end"
                    v-clipboard:copy="displayResult"
                  >
                    <img
                      class="w-5 pt-2"
                      @click="changeSvgColor"
                      id="copypaste"
                      src="../assets/copy-paste.svg"
                    >
                  </button>
                  <button
                    v-show="coverType === 'Hardcover'&& coverOption=== false"
                    tabindex="-2"
                    @mouseenter="clipOut"
                    @mouseleave="leave"
                    type="button"
                    class="w-1/12 copy-button flex self-start justify-end"
                    v-clipboard:copy="displayResultForHardCover"
                  >
                    <img
                      class="w-5 pt-2"
                      @click="changeSvgColor"
                      id="copypaste"
                      src="../assets/copy-paste.svg"
                    >
                  </button>
                  <button
                    v-show="coverType === 'Hardcover'&& coverOption"
                    tabindex="-2"
                    @mouseenter="clipOut"
                    @mouseleave="leave"
                    type="button"
                    class="w-1/12 copy-button flex self-start justify-end"
                    v-clipboard:copy="displayResultForHardCoverWithOmslag"
                  >
                    <img
                      class="w-5 pt-2"
                      @click="changeSvgColor"

                      src="../assets/copy-paste.svg"
                    >
                  </button> -->
                </div>

                <!-- url-->
                <div class="px-6 xlg:px-0 flex bg-grey-800 lg:w-3/4">
                  <div class="flex pb-5">
                    <div class="w-40">
                      <button class="inline-flex items-center test text-xl hover:text-white hover:border-grey-500 leading-normal text-base cursor-pointer" tabindex="-2" v-clipboard:copy="currentRoute" @click="changeCopyText"
                      >
                        <img v-show="!copied" class="inline-block mr-2 w-4" src="../assets/link-icon.svg" alt="">
                        <span v-show="!copied">Kopier link</span>
                        <span v-show="copied">Tekst kopiert til utklippstavle 🎉</span>
                        <span
                          class="tooltiptext shadow  absolute p-2 text-grey-800  bg-white border border-grey-500  rounded-md">kopier til utklippstavle</span>
                      </button>
                    </div>
                  </div>
                </div>

              </div>

              <!--Overtrekk-->

              <div class="mt-5 flex-col lg:ml-16 ">
                <h3 class="pt-8 leading-none text-lg">OVERTREKK</h3>
                <div class="flex sticky top-32 ">

                  <div v-bind:style="[drawingWidth]"></div>
                  <div v-if="coverType === 'Hardcover'" class="w-6 text-green-500 pb-1"></div>
                  <div
                    v-if="coverType === 'Softcover'"
                    class="w-18 text-xl text-green-500 pb-1 leading-none"
                  >{{ result }} mm
                  </div>
                  <div
                    v-if="coverType === 'Hardcover'"
                    class="w-18 text-xl text-green-500 pb-1 leading-none"
                  >{{ hardCoverResult }} mm
                  </div>

                  <div
                    v-if="coverType === 'Hardcover'"
                    class="flex justify-end w-6 text-green-500 pb-1"
                  ></div>
                  <div v-bind:style="[drawingWidth]"></div>
                </div>

                <div class="flex">
                  <div v-bind:style="[drawingWidth, drawingHeight]" class="border border-grey-500"></div>
                  <transition name="fade">
                    <div
                      v-if="coverType === 'Hardcover'"
                      v-bind:style="[]"
                      class="w-6 text-xxl pr-4 flex border-t border-b border-grey-500  items-center shadow-xl"
                    >
                      <div class="transform rotate-90 flex items-center text-xxl pt-3">steg</div>
                    </div>
                  </transition>
                  <div
                    v-bind:style="[]"
                    class="w-16 text-xxl pr-4 flex border-2 border-grey-500 items-center shadow-xl"
                  >
                    <div class="transform rotate-90 flex text-xl pb-2 text-xxl ">Rygg</div>
                  </div>
                  <transition name="fade">
                    <div
                      v-if="coverType === 'Hardcover'"
                      v-bind:style="[]"
                      class="w-6 text-xxl flex border-t border-b border-grey-500  shadow-xl"
                    >
                      <div class="pt-2 transform rotate-90 flex items-center text-xxl">steg</div>
                    </div>
                  </transition>
                  <div
                    v-if="coverType === 'Softcover'"
                    v-bind:style="[drawingWidth, drawingHeight]"
                    class="border border-grey-500 text-green-500 pl-2 pt-2 text-xl"
                  >{{ bredde }} x {{ hoyde }} mm
                  </div>
                  <div
                   v-if="coverType === 'Hardcover'"
                    v-bind:style="[drawingWidth, drawingHeight]"
                    class="border border-grey-500 text-green-500 pl-2 pt-2 text-xl"
                  >{{ realWidth }} x {{ realHeight }} mm
                  </div>

                </div>
                <div class="flex">
                  <div v-bind:style="[drawingWidth]"></div>
                  <div
                    v-if="coverType === 'Hardcover'"
                    class="flex justify-center w-32 text-green-500 border-b border-dotted border-green-500 text-xl"
                  > ← {{ resultPlusSteg }} mm →
                  </div>
                  <div v-bind:style="[drawingWidth]"></div>

                </div>

                <p  v-if="coverType === 'Softcover'" class=" max-w-md text-white text-xl py-5">Dokumentformat <br>
                   i bredden: {{ documentWidthSoftCover }} mm,
                   <br> i høyden:
                  <span v-if="coverType === 'Softcover'">{{ hoyde }} mm </span>
                    </p>

                <p v-if="coverType === 'Hardcover'" class=" max-w-md text-white text-xl py-5">Dokumentformat i bredden: {{ documentWidthHardCover }} mm.  Dokumentformat i høyden:
                 <span  v-if="coverType === 'Hardcover'">{{ realHeight }} mm </span>
                    (+10 mm som går rundt pappen og limes under forsatsen)</p>
              </div>

              <!--Vareomslag-->
              <transition name="fade">
                <div v-show="coverOption && coverType === 'Hardcover'" class="mt-8 flex-col lg:ml-16">
                  <h3 class="pt-8 pb-5 text-lg">VAREOMSLAG</h3>
                  <div class="flex">
                    <div class="w-18 flex">
                      <div class="flex items-end leading-none  pb-1">{{ klafferlength }} mm</div>
                    </div>

                    <div v-bind:style="[drawingWidth]"></div>

                    <div
                      class="flex  text-xl items-end w-28 lg:px-4 text-green-500 leading-none pb-1 "
                    >{{ lossRygg }} mm
                    </div>

                    <div v-bind:style="[drawingWidth]"></div>
                    <div class="w-18 flex text-center">
                      <div class="flex  leading-none pb-1 ">{{klafferlength }} mm</div>
                    </div>
                  </div>

                  <div class="flex">
                    <div v-bind:style="[ drawingHeight]" class="w-16 border border-grey-500 flex">
                      <div
                        class="flex self-center transform rotate-90 md:pb-4 text-xl"
                      >klaff
                      </div>
                    </div>
                    <div
                      v-bind:style="[drawingWidth, drawingHeight]"
                      class="border-t border-b border-grey-500"
                    ></div>

                    <div
                      class="w-16 text-xxl pr-4 flex border-2 border-grey-500 text-white items-center shadow-xl"
                    >
                      <div class="transform rotate-90 flex text-xl pt-1 ">Løsrygg </div>
                    </div>

                    <div
                      v-bind:style="[drawingWidth, drawingHeight]"
                      class="border-t border-b border-grey-500 text-green-500 pl-2 pt-2 text-xl"
                    >{{ realWidth }} x {{ realHeight }} mm
                    </div>
                    <div v-bind:style="[ drawingHeight]" class="w-16 border border-grey-500 flex">
                      <div
                        class="flex self-center transform rotate-90 flex md:pb-4 text-xl items-center"
                      >klaff
                      </div>
                    </div>
                  </div>

                  <div class="flex">
                    <div v-bind:style="[drawingWidth]"></div>
                    <div v-bind:style="[drawingWidth]"></div>
                    <div class="flex justify-center w-32 text-green-500 pb-1"></div>
                    <div v-bind:style="[drawingWidth]"></div>
                    <div v-bind:style="[drawingWidth]"></div>
                  </div>
                  <p class="text-white max-w-md  text-xl py-5">Dokumentformat i bredden: {{ documentWidthOverdraft }} mm. Dokumentformat i høyden: {{ realHeight }}  mm. </p>
                </div>
              </transition>
              <!--Drawing-->
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>
  .copy-button:hover {
    filter: brightness(0.7) sepia(1) hue-rotate(149deg) saturate(2);
  }
</style>

<script>

  import papers from "../paper";
  import forsatsPapers from "../paper-forsats";

  export default {
    name: "Main",
    data() {
      return {
        hoyde: 297,
        bredde: 210,
        isOpen: false,
        copied: false,
        paperTypes: papers,
        forsatsPaperTypes: forsatsPapers,
        forsatsPaperType: "galerie-art-matt",
        numberOfPages: 68,
        paperType: "galerie-art-matt",
        weight: 0,
        forsatsweight: 0,
        selectedOvertrekkTykkelse: 0,
        coverType: "Softcover",
        calcSpine: " ",
        steg: 7,
        klafferlength: 60,
        coverOption: false,
        selectedOverdraft: null,
        showPages: false,
        overdrafts: [
          {variantId: 224, value: 2},
          {variantId: 225, value: 2.1},
          {variantId: 226, value: 2.3},
          {variantId: 227, value: 2.5},
          {variantId: 228, value: 3}
        ],
        covers: [
          {text: 200, value: 0.26},
          {text: 250, value: 0.31},
          {text: 300, value: 0.35}
        ],
      };

    },
    methods: {
      changeCopyText: function () {
        this.copied = true;
        setTimeout(() => (this.copied = false), 2000);
        event.preventDefault();
      },
      changeSvgColor: function () {
        document.querySelector("#copypaste").classList.add("changecolor");
        setTimeout(
          () =>
            document.querySelector("#copypaste").classList.remove("changecolor"),
          2000
        );
      },

      clipOut: function () {
        let clip = document.querySelector("#clipout");
        clip.style.border = "1px dashed grey";
      },
      leave: function () {
        let clip = document.querySelector("#clipout");
        clip.style.border = "1px dashed #232323";
      },

      parseQuery: function () {
        const query = this.$route.query;
        const self = this;

        function assignValue(param, data) {
          if (query[param] !== undefined)
            self[data] = query[param];
        }

        function assignNumberValue(param, data) {
          const val = Number(query[param]);
          if (!isNaN(val)) self[data] = val;
        }

        function assignBooleanValue(param, data) {
          const val = query[param];
          if (val !== undefined)
            self[data] = val === "true";
        }

        function matchingListValue(val, list) {
          let matchingVal = list.find(x => x.value === val);
          return matchingVal
            ? matchingVal.value
            : list[0].value;
        }

        function assignComboNumberValue(param, data, list) {
          let val = Number(query[param]);
          if (!isNaN(val)) {
            self[data] = matchingListValue(val, list);
          }
        }

        function assignComboValue(param, data, list) {
          let val = query[param];
          if (val) {
            self[data] = matchingListValue(val, list);
          }
        }

        assignValue("pages", "numberOfPages");
        assignComboValue("ptype", "paperType", this.paperTypes);
        assignComboNumberValue("weight", "weight", this.papersList);
        assignNumberValue("width", "bredde");
        assignNumberValue("height", "hoyde");
        assignValue("cover", "coverType");

        // hard cover
        if (this.coverType === "Softcover")
          return;

        assignComboValue("forsats", "forsatsPaperType", this.forsatsPaperTypes);
        assignComboNumberValue("forsatsweight", "forsatsweight", this.forsatsPapersList);
        assignComboNumberValue("overdraft", "selectedOverdraft", this.overdrafts);
        assignBooleanValue("coverOption", "coverOption");

        if (this.coverOption) {
          assignNumberValue("klafferlength", "klafferlength");
        }
      }
    },


    computed: {
      drawingWidth: function () {
        return this.bredde < 254
          ? {width: this.bredde + "px"}
          : {width: 254 + "px"};
      },
      realWidth: function () {
        return Number(this.bredde + 5);
      },
      documentWidthSoftCover: function(){
       let a = Number(this.bredde*2 + this.numberOfSheets * this.weight + this.selectedOvertrekkTykkelse * 2)
       if (isNaN(a)) a = 400;
        return a.toFixed(1);
      },
      documentWidthHardCover: function(){
        return Number(this.bredde*2 + 5 )+ Number(this.resultPlusSteg)
      },
      documentWidthOverdraft: function() {
        return Number(this.bredde*2 + 5 ) + Number(this.resultPlusSteg) + Number(this.klafferlength*2)
      },
      realHeight: function () {
        return Number(this.hoyde + 6);
      },

      drawingHeight: function () {
        return this.hoyde >= this.styleHeightIfBreddeMore
          ? {height: this.styleHeightIfBreddeMore + "px"}
          : {height: this.hoyde + "px"};
      },

      klaffersize: function () {
        return this.klafferlength + "px";
      },
      styleHeightIfBreddeMore: function () {
        return (this.hoyde * 254) / this.bredde;
      },
      displayPages: function () {
        return " " + this.numberOfPages + " sider ";
      },
      displaySheets: function () {
        return this.numberOfSheets + " ark ";
      },
      result: function () {
        let x = Number(this.numberOfSheets * this.weight + this.selectedOvertrekkTykkelse * 2);
        if (isNaN(x)) x = 0;
        return x.toFixed(1);
      },
      resultPlusSteg: function () {
        let sum = Number(this.hardCoverResult) + Number(this.steg * 2);
        return sum;
      },
      hardCoverResult: function () {
        let x = Number(this.numberOfSheets * this.weight) +
            (this.selectedOverdraft * 2) + Number(this.forsatsweight * 4);
        if (isNaN(x)) x = 0;
        return x.toFixed(1);
      },

      lossRygg: function () {
        let sum = (Number(this.hardCoverResult) + Number(1)).toFixed(1);
        return sum;
      },
      displayResult: function () {
        return (
          "Ryggbredde: " +
          this.result +
          " " +
          "mm " +
          "\n " +
          this.numberOfPages + ":" + " " +
          " sider " +
          "\n" +
          this.numberOfSheets + ":" +
          "ark "
        );
      },
      displayResultForHardCover: function () {
        return (
          "Ryggbredde: " +
          this.hardCoverResult +
          "mm " +
          "\n " +
          this.numberOfPages +
          " sider " +
          "\n" +
          this.numberOfSheets +
          "ark "
        );
      },
      displayResultForHardCoverWithOmslag: function () {
        return (
          "Ryggbredde: " +
          this.hardCoverResult +
          "mm " +
          "\n " +
          this.numberOfPages +
          " sider " +
          "\n" +
          this.numberOfSheets +
          "ark " + "\n " +
          "Løssrygg: " +
          this.lossRygg +
          " " +
          " mm " +
          "\n "

        );
      },
      papersList: function () {
        return papers.find(x => x.value === this.paperType).weights;
      },
      forsatsPapersList: function () {
        return forsatsPapers.find(x => x.value === this.paperType).weights;
      },
      realPages: function () {
        return " " + Math.round(this.numberOfPages / 2) * 2;
      },
      numberOfSheets: function () {
        return this.realPages / 2;
      },
      currentRoute: function () {
        let route =
          location.host + "/#/ryggkalkulator?" +
          "pages=" + this.numberOfPages +
          "&ptype=" + this.paperType +
          "&weight=" + this.weight +
          "&height=" + this.hoyde +
          "&width=" + this.bredde +
          "&cover=" + this.coverType;
        if (this.coverType === "Hardcover")
          route += "&" + this.hardCoverSubroute;
        return route;
      },
      hardCoverSubroute: function () {
        let route = "forsats=" + this.forsatsPaperType +
          "&forsatsweight=" + this.forsatsweight +
          "&overdraft=" + this.selectedOverdraft +
          "&coverOption=" + this.coverOption;
        if (this.coverOption)
          route += "&klafferlength=" + this.klafferlength;
        return route;
      }
    },

    created() {
      const handleEscape = e => {
        if (e.key === "Esc" || e.key === "Escape") {
          this.isOpen = false;
          this.btnText = "Copy!";
        }
      };

      document.addEventListener("keydown", handleEscape);

      this.$once("hook:beforeDestroy", () => {
        document.removeEventListener("keydown", handleEscape);
      });

      this.parseQuery();
    },

    watch: {
      $route: "parseQuery"
    }
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
  }
</style>
